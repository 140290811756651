/* eslint-disable import/no-cycle */
import { FC } from 'react';

import SbEditable from 'storyblok-react';

import { RichTextSection } from '@/types.d';

import DynamicComponent from '../..';

const Content: FC<{
  content: RichTextSection;
}> = ({ content }) => {
  if (!content.story) return null;
  return (
    <SbEditable content={content}>
      <DynamicComponent content={content.story.content} />
    </SbEditable>
  );
};

export default Content;
