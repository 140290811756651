import { FC } from 'react';

import { Box } from '@chakra-ui/react';
import chunk from 'lodash/chunk';
import SbEditable from 'storyblok-react';

import { Section } from '@/components/Layout';
import { Dots, H2, VStack, HStack, FadeOnScroll } from '@/elements';
import useBreakpointValue from '@/hooks/breakpoint';
import useCarouselPositionManager from '@/hooks/carousel';
import { Gallery as TGallery } from '@/types.d';

import Logo from './Logo';

const Gallery: FC<{
  content: TGallery;
}> = ({ content }) => {
  const pages = chunk(content.images, 6);
  const { pos, goTo } = useCarouselPositionManager(pages.length, {
    autoPlayInterval: 6000,
  });
  const { columnCount, rowCount, imageSize } = useBreakpointValue({
    base: { columnCount: 2, rowCount: 3, imageSize: 100 },
    sm: { columnCount: 2, rowCount: 3, imageSize: 140 },
    md: { columnCount: 3, rowCount: 2, imageSize: 140 },
  });

  const activePage = pages[pos];
  const gapSize = 20;
  const rows = chunk(activePage, columnCount);

  return (
    <SbEditable content={content}>
      <Box my='xl'>
        <Section>
          <VStack>
            <FadeOnScroll>
              <H2 pb='lg'>{content.title}</H2>
            </FadeOnScroll>
            <VStack
              justifyContent='center'
              minHeight={`${(imageSize + gapSize) * rowCount}px`}
              spacing={`${gapSize}px`}
            >
              {rows.map((row, index) => (
                <HStack
                  // eslint-disable-next-line react/no-array-index-key
                  key={`${pos}-${index}`}
                  gridGap={gapSize}
                  justifyContent='space-around'
                  spacing={`${gapSize}px`}
                  w='100%'
                >
                  {row.length === 1 ? (
                    <FadeOnScroll distance='40px'>
                      <Logo image={row[0]} size={imageSize} />
                    </FadeOnScroll>
                  ) : (
                    <FadeOnScroll distance='40px'>
                      {row.map(img => (
                        <Logo key={img.id} image={img} size={imageSize} />
                      ))}
                    </FadeOnScroll>
                  )}
                </HStack>
              ))}
            </VStack>
            <Dots active={pos} length={pages.length} onChange={goTo} />
          </VStack>
        </Section>
      </Box>
    </SbEditable>
  );
};

export default Gallery;
