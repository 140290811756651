import { Box } from '@chakra-ui/react';

const Top = props => (
  <svg
    fill='none'
    height='47'
    viewBox='0 0 373 47'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      clipRule='evenodd'
      d='M373 10.7632V47H0V45.9789C86.2361 29.5477 253.793 0 305.123 0C340.053 0 361.329 5.33519 373 10.7632Z'
      fill='#1328BB'
      fillRule='evenodd'
    />
  </svg>
);

const Bottom = props => (
  <svg
    fill='none'
    height='49'
    viewBox='0 0 373 49'
    width='373'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      clipRule='evenodd'
      d='M373 2.18196V0H0V32.2241C21.3877 46.7284 59.1235 51.6422 133.134 47.5901C211.163 43.318 317.392 17.7695 373 2.18196Z'
      fill='#1328BB'
      fillRule='evenodd'
    />
  </svg>
);

export default function DesktopBackground() {
  return (
    <>
      <Box
        bg='linear-gradient(50deg, #f32121, #FF9750);'
        bottom='0px'
        left='50%'
        minW='calc(100vw + 16px)'
        position='absolute'
        top='0'
        transform='translateX(-50vw)'
        zIndex='-1'
      >
        <Box
          bottom='100%'
          height='120px'
          left='0'
          position='absolute'
          right='0'
        >
          <Top height='100%' preserveAspectRatio='none' width='100%' />
        </Box>
        <Box height='80px' left='0' position='absolute' right='0' top='100%'>
          <Bottom height='100%' preserveAspectRatio='none' width='100%' />
        </Box>
      </Box>
    </>
  );
}
