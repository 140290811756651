import createDynamicComponents from '../support/createDynamicComponents';

import FeatureHero from './FeatureHero';
import RegularHero from './RegularHero';

export const components = {
  'feature-hero': FeatureHero,
  'regular-hero': RegularHero,
};

const DynamicHero = createDynamicComponents(components);

export default DynamicHero;
