import { Box, Text } from '@chakra-ui/react';

import { Link as StoryblokLink } from '@/components/storyblok/components';
import { HStack, Image, VStack, Hr, FadeOnScroll } from '@/elements';
import useBreakpointValue from '@/hooks/breakpoint';
import { error } from '@/services/log';
import * as Storyblok from '@/types.d';

import Section from '../Section';

import SocialLinks from './SocialLinks';

export default function Footer({ footerLinks }: Storyblok.Footer) {
  const isMobile = useBreakpointValue({ base: true, md: false });

  if (isMobile) {
    return (
      <Box bg='brand.lightGrey'>
        <Section>
          <VStack alignItems='center' pb='xl' pt='lg' spacing='sm'>
            <Image pb='md' pt='sm' src='/logo/FullLogo.svg' width='160px' />
            <Hr variant='light' />
            <HStack
              alignItems='flex-start'
              flexWrap='wrap'
              justifyContent='flex-start'
              spacing='xl'
              width='100%'
            >
              {footerLinks.map(column => (
                <VStack
                  key={column._uid}
                  alignItems='center'
                  flex={1}
                  marginInlineStart='0 !important'
                  minWidth='180px'
                  pt='xl'
                  spacing='md'
                  textAlign='center'
                >
                  <Text
                    color='brand.secondary'
                    fontFamily='heading'
                    fontSize='md'
                    mb='md'
                  >
                    {column.title}
                  </Text>
                  {column.links.map(row => {
                    if (row.component !== 'cta') {
                      error(`${row.component} is not allowed in the footer`);
                      return null;
                    }
                    return (
                      <FadeOnScroll key={row._uid}>
                        <StoryblokLink link={row.link} variant='muted'>
                          {row.text}
                        </StoryblokLink>
                      </FadeOnScroll>
                    );
                  })}
                </VStack>
              ))}
            </HStack>
          </VStack>
          <Hr mt='md' variant='light' />
          <VStack justifyContent='space-between' py='lg' spacing='lg'>
            <SocialLinks />
            <Hr variant='light' />
            <Text fontSize='xs' opacity='0.4' textAlign='center'>
              © {new Date().getFullYear()} | All Rights Reserve
            </Text>
          </VStack>
        </Section>
      </Box>
    );
  }
  return (
    <Box bg='brand.lightGrey'>
      <Section>
        <HStack
          alignItems='flex-start'
          justifyContent='space-between'
          pb='xl'
          pt='lg'
          spacing='lg'
        >
          <Image src='/logo/FullLogo.svg' width='160px' />
          <HStack alignItems='flex-start' spacing='xl'>
            {footerLinks.map(column => (
              <VStack key={column._uid} alignItems='flex-start' spacing='12px'>
                <Text
                  color='brand.secondary'
                  fontFamily='heading'
                  fontSize='md'
                  mb='md'
                >
                  {column.title}
                </Text>
                {column.links.map(row => {
                  if (row.component !== 'cta') {
                    error(`${row.component} is not allowed in the footer`);
                    return null;
                  }
                  return (
                    <FadeOnScroll key={row._uid}>
                      <StoryblokLink link={row.link} variant='mutedSimple'>
                        {row.text}
                      </StoryblokLink>
                    </FadeOnScroll>
                  );
                })}
              </VStack>
            ))}
          </HStack>
        </HStack>
        <HStack justifyContent='space-between' pt='xl'>
          <Text fontSize='xs' opacity='0.4'>
            © {new Date().getFullYear()} | All Rights Reserved | Kuinua
          </Text>
          {/* <SocialLinks /> */}
        </HStack>
      </Section>
    </Box>
  );
}
