import { createComponent } from '@/lib/utils/chakra';

export const HeaderContainer = createComponent('div', {
  baseStyle: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    zIndex: 'overlay',
    display: 'flex',
    alignItems: 'center',
  },
});

// eslint-disable-next-line import/prefer-default-export
export const Background = createComponent('div', {
  baseStyle: {
    position: 'absolute',
    left: 0,
    right: 0,
    height: '20px',
    bottom: 0,
    background:
      'linear-gradient(0deg, #FBFBFB 0%, rgba(255, 255, 255, 0) 100%)',
    zIndex: -1,
  },
});
