/* eslint-disable @typescript-eslint/camelcase */
import StoryblokClient from 'storyblok-js-client';

export default class Client {
  private token: string;

  public devMode: boolean;

  private query = {};

  constructor(token: string, devMode = false) {
    this.token = token;
    this.devMode = devMode;
    this.client = new StoryblokClient({
      accessToken: token,
      cache: {
        clear: 'auto',
        type: 'memory',
      },
    });
  }

  private client: StoryblokClient;

  getCacheVersion() {
    return this.client.cacheVersion;
  }

  enrichParams(params: { [key: string]: any } = {}) {
    const enrichedParams = params;
    if (
      this.getQuery('_storyblok') ||
      this.devMode ||
      (typeof window !== 'undefined' && window.storyblok)
    ) {
      enrichedParams.version = 'draft';
    }

    if (
      typeof window !== 'undefined' &&
      typeof window.StoryblokCacheVersion !== 'undefined'
    ) {
      enrichedParams.cv = window.StoryblokCacheVersion;
    }

    return enrichedParams;
  }

  // ask Storyblok's Content API for content of story
  async get(slug: string, params: { [key: string]: any } = {}) {
    const enrichedParams = this.enrichParams(params);
    try {
      return await this.client.get(slug, enrichedParams);
    } catch (e) {
      if (e?.response?.status === 404) {
        return null;
      }
      throw e;
    }
  }

  async getStories(slug: string, params: { [key: string]: any } = {}) {
    const enrichedParams = this.enrichParams(params);

    enrichedParams.by_slugs = slug;

    try {
      return await this.client.getAll('cdn/stories', enrichedParams);
    } catch (e) {
      if (e?.response?.status === 404) {
        return null;
      }
      throw e;
    }
  }

  // initialize the connection between Storyblok & Next.js in Visual Editor
  // eslint-disable-next-line class-methods-use-this
  initEditor(reactComponent) {
    if (window.storyblok) {
      window.storyblok.init();

      // reload on Next.js page on save or publish event in Storyblok Visual Editor
      window.storyblok.on(['change', 'published'], () =>
        window.location.reload()
      );

      // Update state.story on input in Visual Editor
      // this will alter the state and replaces the current story with a current raw story object and resolve relations
      window.storyblok.on('input', event => {
        if (
          event.story.content._uid === reactComponent.state.story.content._uid
        ) {
          // eslint-disable-next-line no-param-reassign
          event.story.content = window.storyblok.addComments(
            event.story.content,
            event.story.id
          );
          window.storyblok.resolveRelations(
            event.story,
            ['featured-articles.articles'],
            () => {
              reactComponent.setState({
                story: event.story,
              });
            }
          );
        }
      });
    }
  }

  setQuery(query = {}) {
    this.query = query;
  }

  getQuery(param) {
    return this.query[param];
  }

  bridge() {
    if (!this.getQuery('_storyblok') && !this.devMode) {
      return '';
    }
    return (
      <script
        src={`//app.storyblok.com/f/storyblok-latest.js?t=${this.token}`}
      />
    );
  }
}
