import { FC } from 'react';

import { Box, useTheme } from '@chakra-ui/react';
import SbEditable from 'storyblok-react';

import { Section } from '@/components/Layout';
import { FadeOnScroll, H2, H3, VStack } from '@/elements';
import { CtaSection as CtaSectionContent } from '@/types.d';

import { CtaRow } from '../../components/Cta';

import { BackgroundImage } from './styled';

const CtaSection: FC<{
  content: CtaSectionContent;
}> = ({ content }) => {
  const theme = useTheme();
  let materialPlaceholder = 'placeholder.svg';
  let bg = 'linear-gradient(50deg, #f32121, #FF9750);';

  if (content.colorVariant === 'red') {
    materialPlaceholder = 'coral.svg';
    bg = theme.colors.brand.coral;
  }

  return (
    <SbEditable content={content}>
      <Box bg={bg} position='relative' py='xl'>
        <Box position='relative' zIndex={1}>
          <Section>
            <Box p='lg' display='flex' maxWidth='460px'>
              <VStack align='flex-start' className='cta-section'>
                <FadeOnScroll>
                  {content.title && <H3 variant='light'>{content.title}</H3>}
                  {content.description && (
                    <H2 variant='light'>{content.description}</H2>
                  )}
                  <Box pt='lg'>
                    <CtaRow
                      ctas={content.ctas}
                      direction={{ base: 'column', sm: 'row' }}
                      variant='dark'
                    />
                  </Box>
                </FadeOnScroll>
              </VStack>
            </Box>
          </Section>
        </Box>
      </Box>
    </SbEditable>
  );
};

export default CtaSection;
