import { FC } from 'react';

import SbEditable from 'storyblok-react';

import { Section } from '@/components/Layout';
import { VStack } from '@/elements';
import { RichTextSection } from '@/types.d';

import { RichText } from '../../components';

const Content: FC<{
  content: RichTextSection;
}> = ({ content }) => {
  return (
    <SbEditable content={content}>
      <Section>
        <VStack
          alignItems='left'
          maxWidth='800px'
          mb='xl'
          mx='auto'
          spacing='md'
        >
          <RichText>{content.body}</RichText>
        </VStack>
      </Section>
    </SbEditable>
  );
};

export default Content;
