import { Box } from '@chakra-ui/react';

import { A, FadeOnScroll, HStack, Image } from '@/elements';

export default function SocialLinks({ size = 24 }) {
  return (
    <Box>
      <HStack spacing='md'>
        <FadeOnScroll>
          <A
            href='https://www.linkedin.com/company/kuinua/'
            target='__blank'
            variant='blank'
          >
            <Image
              alt='linkedin icon'
              height={`${size}px`}
              src='/icons/social/linkedin.svg'
              width={`${size}px`}
            />
          </A>
          <A
            href='https://www.facebook.com/kuinua/'
            target='__blank'
            variant='blank'
          >
            <Image
              alt='facebook icon'
              height={`${size}px`}
              src='/icons/social/facebook.svg'
              width={`${size}px`}
            />
          </A>
          <A href='https://twitter.com/kuinua' target='__blank' variant='blank'>
            <Image
              alt='twitter icon'
              height={`${size}px`}
              src='/icons/social/twitter.svg'
              width={`${size}px`}
            />
          </A>
          <A
            href='https://www.instagram.com/kuinua/'
            target='__blank'
            variant='blank'
          >
            <Image
              alt='instagram icon'
              height={`${size}px`}
              src='/icons/social/instagram.svg'
              width={`${size}px`}
            />
          </A>
          <A
            href='https://www.youtube.com/channel/UCf514-5lExV6LqYpEu3T_jw'
            target='__blank'
            variant='blank'
          >
            <Image
              alt='youtube icon'
              height={`${size}px`}
              src='/icons/social/youtube.svg'
              width={`${size}px`}
            />
          </A>
        </FadeOnScroll>
      </HStack>
    </Box>
  );
}
