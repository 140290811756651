import { FC } from 'react';

import { Box } from '@chakra-ui/react';
import styled from '@emotion/styled';
import HubspotForm from 'react-hubspot-form';
import SbEditable from 'storyblok-react';

import { Section } from '@/components/Layout';
import config from '@/config';
import { FormSection } from '@/types.d';

const FormStyle = styled.div`
  /* General */
  input[type='date'],
  input[type='email'],
  input[type='number'],
  input[type='password'],
  input[type='tel'],
  input[type='text'],
  input[type='time'],
  input[type='url'],
  textarea,
  select {
    border: 1px solid transparent;
    transition: all 0.4s ease-out;
    box-shadow: ${({ theme }) => theme.boxShadow.input};
    background: ${({ theme }) => theme.colors.brand.lightGrey};
    border-radius: 8px;
    width: 100%;
    max-width: 280px;
    padding: 4px 8px;
    margin: 12px 0 6px;
    font-size: 1em;
    :focus {
      outline: none;
      border-color: ${({ theme }) => theme.colors.brand.darkIndigo};
    }
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p {
    margin-bottom: 1rem;
  }
  textarea {
    max-width: none;
  }
  input[type='checkbox'] {
    margin-right: 8px;
  }
  input[type='radio'] {
    margin-right: 8px;
  }
  ul[role='checkbox'] {
    margin-top: 1rem;
  }
  button,
  input[type='button'],
  .hs-button {
    background: ${({ theme }) => theme.colors.brand.darkIndigo};
    color: ${({ theme }) => theme.colors.brand.lightGrey};
    font-family: ${({ theme }) => theme.fonts.heading};
    font-weight: 500;
    border-radius: 4px;
    padding: 8px 12px;
    margin: 20px 0;
    cursor: pointer;
  }
  a {
    font-weight: bold;
  }
  ul {
    padding-left: 32px;
  }
  li {
    padding-bottom: 12px;
  }
  p {
    padding-bottom: 8px;
  }
  label {
    margin-bottom: 0.5rem;
  }
  strong {
  }

  /* Specific */
  .hs-error-msg {
    color: ${({ theme }) => theme.colors.brand.coral};
  }
  .hs-form-field {
    margin: 4px 0;
  }
  .hs-richtext p {
    margin: 8px 0;
  }
  .form-columns-1 {
    max-width: none;
    margin: 12px 0;
  }
  .form-columns-2 {
    max-width: none;
    & > * {
      padding-right: 12px;
    }
  }
  .inputs-list {
    list-style: none;
    padding-left: 16px;
  }
  .hs-error-msgs {
    list-style: none;
    padding-left: 0;
    font-size: 0.8em;
    line-height: 1.2em;
  }
  li.hs-form-booleancheckbox {
    /* padding-bottom: 0; */
  }
`;

const Form: FC<{
  content: FormSection;
}> = ({ content }) => (
  <SbEditable content={content}>
    <Section>
      <Box maxWidth='600px' mx='auto' my='xl' width='100%'>
        <FormStyle>
          <HubspotForm
            formId={content.formId}
            portalId={config.hubspot.portalId}
          />
        </FormStyle>
      </Box>
    </Section>
  </SbEditable>
);

export default Form;
