import { FC } from 'react';

import {
  AspectRatio,
  Box,
  Text,
  VStack,
  HStack,
  IconButton,
} from '@chakra-ui/react';

import { Section } from '@/components/Layout';
import RoundImage from '@/components/RoundImage';
import { Image } from '@/components/storyblok/components';
import { Dots, H2, ChevronRight, ChevronLeft, FadeOnScroll } from '@/elements';
import useBreakpointValue from '@/hooks/breakpoint';
import useCarouselPositionManager from '@/hooks/carousel';
import usePreloadedImages from '@/hooks/preloadImages';
import { Testimonials as TTestimonials } from '@/types.d';

const Testimonials: FC<{
  content: TTestimonials;
}> = ({ content }) => {
  const { testimonials } = content;
  const { pos, goTo, next, previous } = useCarouselPositionManager(
    testimonials.length,
    {
      autoPlayInterval: 10000,
    }
  );
  const activeItem = testimonials[pos];
  const isMobile = useBreakpointValue({ base: true, md: false });

  usePreloadedImages(testimonials.map(t => t.profilePicture.filename));

  if (isMobile) {
    return (
      <Box my='lg'>
        <Section>
          <FadeOnScroll>
            <H2>{content.title}</H2>
          </FadeOnScroll>
          <VStack mt='lg' spacing='lg'>
            <Box w='100%'>
              <AspectRatio
                background='white'
                borderRadius='50%'
                boxShadow='lg'
                maxW='100px'
                mx='auto'
                ratio={1}
              >
                {activeItem?.profilePicture ? (
                  <FadeOnScroll>
                    <Image
                      borderRadius='full'
                      content={activeItem.profilePicture}
                      height='100%'
                      objectFit='cover'
                      width='100%'
                    />
                  </FadeOnScroll>
                ) : null}
              </AspectRatio>
            </Box>

            <VStack alignItems='center' spacing='md' textAlign='center'>
              <Box alignItems='center' d='flex' flex={1}>
                <IconButton
                  aria-label='Previous'
                  fontSize='lg'
                  icon={<ChevronLeft />}
                  onClick={previous}
                  variant='plain'
                />
                <VStack spacing='md'>
                  {activeItem?.quoteCallout && (
                    <FadeOnScroll>
                      <Text
                        key={`${pos}.callout`}
                        fontFamily='heading'
                        fontSize={{ base: 'md', xl: 'lg' }}
                        lineHeight='lg'
                      >
                        “{activeItem?.quoteCallout}”
                      </Text>
                    </FadeOnScroll>
                  )}
                  <FadeOnScroll>
                    <Text
                      key={`${pos}.quote`}
                      color='brand.mediumGrey'
                      fontFamily='heading'
                      fontSize={{ base: 'sm', lg: 'md' }}
                      lineHeight='lg'
                      px='sm'
                    >
                      {activeItem?.quote}
                    </Text>
                  </FadeOnScroll>
                </VStack>
                <IconButton
                  aria-label='Next'
                  fontSize='lg'
                  icon={<ChevronRight />}
                  onClick={next}
                  variant='plain'
                />
              </Box>
              <FadeOnScroll>
                <Text
                  key={`${pos}.person`}
                  color='brand.blue'
                  fontFamily='heading'
                  fontSize='sm'
                  fontWeight='600'
                  lineHeight='sm'
                  pb='md'
                >
                  {activeItem?.personsName}
                </Text>
              </FadeOnScroll>
              <Dots active={pos} length={testimonials.length} onChange={goTo} />
            </VStack>
          </VStack>
        </Section>
      </Box>
    );
  }

  return (
    <Box my='xl'>
      <Section>
        <FadeOnScroll>
          <H2>{content.title}</H2>
        </FadeOnScroll>
        <HStack spacing='lg' textAlign='left'>
          <IconButton
            aria-label='Previous'
            fontSize='lg'
            icon={<ChevronLeft />}
            onClick={previous}
            variant='plain'
          />
          <Box flex={1}>
            <VStack alignItems='flex-start' spacing='md'>
              {activeItem?.quoteCallout && (
                <FadeOnScroll>
                  <Text
                    key={pos}
                    fontFamily='heading'
                    fontSize={{ base: 'md', xl: 'lg' }}
                    lineHeight='lg'
                  >
                    “{activeItem?.quoteCallout}”
                  </Text>
                </FadeOnScroll>
              )}
              <FadeOnScroll>
                <Text
                  key={`${pos}-heading`}
                  fontFamily='heading'
                  fontSize={{ base: 'sm', xl: 'md' }}
                  lineHeight='1.35em'
                >
                  {activeItem?.quote}
                </Text>
                <Text
                  key={`${pos}-person`}
                  color='brand.blue'
                  fontFamily='heading'
                  fontSize='sm'
                  fontWeight='600'
                  lineHeight='sm'
                  pb='md'
                >
                  — {activeItem?.personsDescription}
                </Text>
                <Dots
                  active={pos}
                  length={testimonials.length}
                  onChange={goTo}
                />
              </FadeOnScroll>
            </VStack>
          </Box>
          <Box flex={1}>
            <FadeOnScroll>
              <RoundImage
                alt={activeItem.profilePicture?.alt}
                maxWidth='360px'
                src={activeItem.profilePicture?.filename}
                title={activeItem.profilePicture?.title}
              />
            </FadeOnScroll>
          </Box>
          <IconButton
            aria-label='Next'
            fontSize='lg'
            icon={<ChevronRight />}
            onClick={next}
            variant='plain'
          />
        </HStack>
      </Section>
    </Box>
  );
};

export default Testimonials;
