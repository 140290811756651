import { FC } from 'react';

import { Box } from '@chakra-ui/react';

import { Section } from '@/components/Layout';
import {
  Dots,
  FadeOnScroll,
  H1,
  H2,
  HStack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  VStack,
  H4,
} from '@/elements';
import useBreakpointValue from '@/hooks/breakpoint';
import useCarouselPositionManager from '@/hooks/carousel';
import usePreloadedImages from '@/hooks/preloadImages';
import { ProductShowcase as TProductShowcase } from '@/types.d';

import RichText from '../../components/RichText';

import DesktopBackground from './DesktopBackground';
import Device from './Device';
import MobileBackground from './MobileBackground';

const ProductShowcase: FC<{
  content: TProductShowcase;
}> = ({ content }) => {
  const images = content.items.map(i => i.screenshot.filename);
  usePreloadedImages(images);
  const isSmall = useBreakpointValue({ base: true, md: false });

  const { pos, goTo } = useCarouselPositionManager(content.items.length, {
    autoPlayInterval: 8000,
  });

  if (isSmall) {
    return (
      <Box p='lg'>
        <Section>
          <Box position='relative'>
            <H1 fontWeight='600' mt='lg' pb='lg' mb='xl'>
              {content.title}
            </H1>
            <VStack position='relative' zIndex='1'>
              <DesktopBackground />
              {content.items.map((item, index) => (
                <HStack
                  key={item._uid}
                  style={{
                    flexDirection: 'column',
                  }}
                  position='relative'
                  spacing='lg'
                  textAlign='left'
                >
                  <HStack flex='1' justify='center'>
                    <VStack
                      align={{ base: 'center', tablet: 'flex-start' }}
                      spacing='md'
                    >
                      <FadeOnScroll distance='30px'>
                        <H1 fontWeight='600' mt='lg' mb='lg' variant='light'>
                          {item.title}
                        </H1>
                        <Box>
                          <RichText variant='light'>
                            {item.description}
                          </RichText>
                        </Box>
                      </FadeOnScroll>
                    </VStack>
                  </HStack>
                  <HStack flex='1' justify='center'>
                    <FadeOnScroll distance='30px'>
                      <img
                        alt=''
                        src={item.screenshot?.filename}
                        style={{ margin: 20 }}
                      />
                    </FadeOnScroll>
                  </HStack>
                </HStack>
              ))}
            </VStack>
          </Box>
        </Section>
      </Box>
    );
  }

  return (
    <Box>
      <Section>
        <Box position='relative'>
          <H1 fontWeight='600' mt='lg' pb='lg' mb='xl'>
            {content.title}
          </H1>
          <VStack py='lg' position='relative' zIndex='1'>
            <DesktopBackground />
            {content.items.map((item, index) => (
              <HStack
                key={item._uid}
                style={{
                  flexDirection: index % 2 === 0 ? 'row' : 'row-reverse',
                }}
                position='relative'
                spacing='md'
                textAlign='left'
              >
                <HStack flex='1' justify='center'>
                  <VStack
                    align={{ base: 'center', tablet: 'flex-start' }}
                    spacing='md'
                  >
                    <FadeOnScroll distance='30px'>
                      <H1 mb='lg' variant='light'>
                        {item.title}
                      </H1>
                      <Box>
                        <RichText variant='light'>{item.description}</RichText>
                      </Box>
                    </FadeOnScroll>
                  </VStack>
                </HStack>
                <HStack flex='1' justify='center'>
                  <FadeOnScroll distance='60px'>
                    <img
                      alt=''
                      src={item.screenshot?.filename}
                      style={{ margin: 20, height: 600 }}
                    />
                  </FadeOnScroll>
                </HStack>
              </HStack>
            ))}
          </VStack>
        </Box>
      </Section>
    </Box>
  );
};

export default ProductShowcase;
