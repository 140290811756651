import { Box, useTheme } from '@chakra-ui/react';

const MainContainer = ({ children, ...rest }) => {
  const theme = useTheme();
  const navHeight = theme.header.height;
  return (
    <Box pt={`${navHeight}px`} {...rest}>
      {children}
    </Box>
  );
};

export default function Main({ children }) {
  return <MainContainer>{children}</MainContainer>;
}
