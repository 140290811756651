import { FC } from 'react';

import { Flex, VStack } from '@chakra-ui/react';
import SbEditable from 'storyblok-react';

import { Section } from '@/components/Layout';
import { H2, Text, FadeOnScroll } from '@/elements';
import { RegularHero as TRegularHero } from '@/types.d';

import { CtaRow } from '../../components/Cta';

import BackgroundImage from './backgroundImage';

const RegularHero: FC<{
  content: TRegularHero;
}> = ({ content }) => {
  return (
    <SbEditable content={content}>
      <Flex
        alignItems='center'
        minHeight={{ base: '280px', md: '400px' }}
        overflow='hidden'
        position='relative'
        zIndex='0'
      >
        <BackgroundImage src={content.bgImage?.filename} />
        <Section>
          <FadeOnScroll>
            <VStack align='flex-start' maxWidth='600px' spacing='md'>
              <H2>{content.title}</H2>
              {/* {content.description && ( */}
              <Text size='lg'>
                Please email us at{' '}
                <a
                  style={{ color: 'blue', fontWeight: 800 }}
                  href='mailto:info@kuinua.co'
                >
                  info@kuinua.co{' '}
                </a>
                for all enquiries
              </Text>
              {/* )} */}
              {content.ctas && <CtaRow ctas={content.ctas} />}
            </VStack>
          </FadeOnScroll>
        </Section>
      </Flex>
    </SbEditable>
  );
};

export default RegularHero;
