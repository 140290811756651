import { FC } from 'react';

import { AspectRatio } from '@chakra-ui/react';
import styled from '@emotion/styled';

import { Image } from '@/components/storyblok/components';
import { Image as TImage } from '@/types.d';

const GreyImage = styled(Image)`
  filter: grayscale(100%);
  &:hover {
    /* filter: grayscale(0%); */
  }
`;

const Logo: FC<{
  image: TImage;
  size: number;
}> = ({ image, size, ...rest }) => {
  return (
    <AspectRatio ratio={1} width={size} {...rest}>
      <GreyImage
        content={image}
        height='100%'
        objectFit={'contain !important' as any}
        objectPosition='center'
        width='100%'
      />
    </AspectRatio>
  );
};

export default Logo;
