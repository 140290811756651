import { useState } from 'react';

import { Box } from '@chakra-ui/react';

import { Image } from '@/elements';

export default function({ src }) {
  const [isBgImageLoaded, setIsBgImageLoaded] = useState(false);
  const [isFgImageLoaded, setIsFgImageLoaded] = useState(false);
  const shouldShow = isBgImageLoaded && isFgImageLoaded;
  return (
    <Box
      maxH='100%'
      maxWidth='80%'
      opacity={shouldShow ? '1' : '0'}
      pointerEvents='none'
      position='absolute'
      right='0'
      top='0'
      width='600px'
      zIndex='-1'
    >
      <Image
        height='100%'
        onLoad={() => setIsBgImageLoaded(true)}
        src={src || '/assets/regularHero/placeholder.png'}
        width='100%'
      />
      <Image
        height='100%'
        left='0'
        onLoad={() => setIsFgImageLoaded(true)}
        position='absolute'
        src='/assets/regularHero/frame.png'
        top='0'
        width='100%'
        zIndex='1'
      />
    </Box>
  );
}
