import { Box } from '@chakra-ui/react';

import { extendComponent } from '@/lib/utils/chakra';

export const IsSmall = extendComponent(Box)({
  display: { base: 'inherit', md: 'none' },
});
export const IsLarge = extendComponent(Box)({
  display: { base: 'none', md: 'inherit' },
});
