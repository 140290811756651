import { useEffect } from 'react';

export default function usePreloadedImages(images: Array<string>) {
  useEffect(() => {
    images.forEach(testimonial => {
      const img = new Image();
      img.src = testimonial;
    });
  }, [images, images.length]);
}
