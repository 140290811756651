import { FC } from 'react';

import { List as ChakraList, Text } from '@chakra-ui/react';
import SbEditable from 'storyblok-react';

import { CtaRow } from '@/components/storyblok/components/Cta';
import { H2, HStack, Image, VStack } from '@/elements';
import { List as ListContent } from '@/types.d';

const FeatureList: FC<{
  content: ListContent;
}> = ({ content }) => {
  return (
    <SbEditable content={content}>
      <VStack
        align='flex-start'
        height='100%'
        justifyContent='space-between'
        spacing='lg'
      >
        <VStack align='flex-start' spacing='lg'>
          <H2 color='brand.blue'>
            {content.title}
            <br />
            {content.price}
          </H2>
          <Text fontFamily='heading' fontWeight='500'>
            {content.subtitle}
          </Text>{' '}
          <VStack>
            <ChakraList spacing='sm'>
              {content.list.map(item => (
                <HStack key={item._uid} alignItems='flex-start'>
                  <Image
                    display='inline'
                    height='24px'
                    pr='sm'
                    src='/icons/plus.svg'
                    width='24px'
                  />
                  <Text fontFamily='heading' fontWeight='500'>
                    {item.content}
                  </Text>
                </HStack>
              ))}
            </ChakraList>
          </VStack>
        </VStack>
        <CtaRow ctas={content.ctas} />
      </VStack>
    </SbEditable>
  );
};

export default FeatureList;
