import { FC } from 'react';

import { AspectRatio, Box, Stack, Flex } from '@chakra-ui/react';
import SbEditable from 'storyblok-react';

import { Section } from '@/components/Layout';
import RoundImage from '@/components/RoundImage';
import { FadeOnScroll, H2, H3, VStack } from '@/elements';
import useBreakpointValue from '@/hooks/breakpoint';
import { ContentSection } from '@/types.d';

import { Image, RichText } from '../../components';
import { CtaRow } from '../../components/Cta';

const Content: FC<{
  content: ContentSection;
}> = ({ content }) => {
  const isMobile = useBreakpointValue({ base: true, md: false });

  if (isMobile) {
    return (
      <SbEditable content={content}>
        <Box my='lg'>
          <Section>
            <VStack maxWidth='mobileContentWidth' mx='auto' spacing='xl'>
              <RoundImage
                alt={content.image?.alt}
                maxWidth='360px'
                src={content.image?.filename}
                title={content.image?.title}
              />
              <VStack align='flex-start' maxWidth='480px' spacing='md'>
                <FadeOnScroll>
                  <H3>{content.title}</H3>
                  {content.subtitle && <H2>{content.subtitle}</H2>}
                  <Box>
                    <RichText>{content.content}</RichText>
                  </Box>
                  {content.ctas && (
                    <Box pt='lg'>
                      <CtaRow ctas={content.ctas} />
                    </Box>
                  )}
                </FadeOnScroll>
              </VStack>
            </VStack>
          </Section>
        </Box>
      </SbEditable>
    );
  }

  return (
    <SbEditable content={content}>
      <Box my='lg'>
        <Section>
          <Stack
            direction={content.reverse ? 'row-reverse' : 'row'}
            spacing='xl'
          >
            <Flex alignItems='center' flex={1}>
              <VStack align='flex-start'>
                <FadeOnScroll>
                  {content.title && <H3>{content.title}</H3>}
                  {content.subtitle && <H2 mb='18px'>{content.subtitle}</H2>}
                  <Box pb='lg'>
                    <RichText>{content.content}</RichText>
                  </Box>
                  {content.ctas && (
                    <Box>
                      <CtaRow ctas={content.ctas} />
                    </Box>
                  )}
                </FadeOnScroll>
              </VStack>
            </Flex>
            <Box flex={1}>
              <AspectRatio
                background='white'
                borderRadius='50%'
                boxShadow='lg'
                maxW='440px'
                mx='auto'
                ratio={1}
              >
                <Image
                  borderRadius='full'
                  content={content.image}
                  height='100%'
                  width='100%'
                />
              </AspectRatio>
            </Box>
          </Stack>
        </Section>
      </Box>
    </SbEditable>
  );
};

export default Content;
