import { FC } from 'react';

import SLink from '@/components/storyblok/components/Link';
import { Cta } from '@/types.d';

const SubNavCta: FC<{
  content: Cta;
}> = ({ content }) => (
  <SLink link={content.link} my='xs' variant='mutedSimple'>
    {content.text}
  </SLink>
);

export default SubNavCta;
