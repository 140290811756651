/* eslint-disable import/no-cycle */
import createDynamicComponents from '../support/createDynamicComponents';

import Basic from './Basic';
import Blog from './Blog';
import Content from './Content';

export const components = {
  page: Basic,
  'blog-post': Blog,
  'content-page': Content,
};

const DynamicPage = createDynamicComponents(components);

export default DynamicPage;
