import { Image } from '@/elements';
import { extendComponent } from '@/lib/utils/chakra';

// eslint-disable-next-line import/prefer-default-export
export const BackgroundImage = extendComponent(Image)({
  left: '0',
  minWidth: '100%',
  objectFit: 'contain',
  position: 'absolute',
  top: '-10%',
  pointerEvents: 'none',
});
