/* eslint-disable import/no-cycle */
import createDynamicComponents from '@/components/storyblok/support/createDynamicComponents';

import { RichText } from '../components'; // We should do this a different way

import ColumnsSection from './Columns';
import Content from './Content';
import CtaSection from './CtaSection';
import FeatureListSection from './FeatureListSection';
import Form from './Form';
import Gallery from './Gallery';
import ProductShowcase from './ProductShowcase';
import RichTextSection from './RichText';
import StatementSection from './StatementSection';
import Story from './Story';
import Testimonials from './Testimonials';

export const components = {
  content: Content,
  'columns-section': ColumnsSection,
  'cta-section': CtaSection,
  'feature-list': FeatureListSection,
  form: Form,
  gallery: Gallery,
  'rich-text': RichText,
  'rich-text-section': RichTextSection,
  story: Story,
  'product-showcase': ProductShowcase,
  testimonials: Testimonials,
  statement: StatementSection,
};

export default createDynamicComponents(components);
