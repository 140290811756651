import { useCallback, useEffect, useState } from 'react';

import { useTheme } from '@chakra-ui/react';
import { debounce } from 'lodash';

const parseBreakpoint = str => parseInt(str, 10);

const sortBreakpoints = (breakpoints, asc = false) => (a, b) =>
  parseBreakpoint(breakpoints[asc ? b : a]) -
  parseBreakpoint(breakpoints[asc ? a : b]);

const getClosesValue = (values, breakpoint, breakpoints) => {
  let index = Object.keys(values).indexOf(breakpoint);

  if (index !== -1) {
    return values[breakpoint];
  }

  let stopIndex = breakpoints.indexOf(breakpoint);

  while (stopIndex >= 0) {
    const key = breakpoints[stopIndex];

    if (values[key] != null) {
      index = stopIndex;
      break;
    }

    stopIndex -= 1;
  }

  if (index !== -1) {
    return values[breakpoints[index]];
  }

  return undefined;
};

const isCustomBreakpoint = key => Number.isNaN(Number(key));

function useCurrentBreakpoint() {
  const [currentBreakPoint, setCurrentBreakPoint] = useState(null);
  const theme = useTheme();

  const setBreakPoint = useCallback(() => {
    setCurrentBreakPoint(
      Object.keys(theme.breakpoints)
        .filter(isCustomBreakpoint)
        .sort(sortBreakpoints(theme.breakpoints, true))
        .find(
          key =>
            window.matchMedia(`(min-width: ${theme.breakpoints[key]})`)?.matches
        )
    );
  }, [theme.breakpoints]);

  useEffect(() => setBreakPoint(), []);

  useEffect(() => {
    const debounced = debounce(setBreakPoint, 200);

    window.addEventListener('resize', debounced);
    return () => window.removeEventListener('resize', debounced);
  }, [setBreakPoint]);

  return currentBreakPoint;
}

export default function useBreakpointValue(breakpointValues) {
  const breakpoint = useCurrentBreakpoint();
  const theme = useTheme();
  const sortedBreakpoints = Object.keys(breakpointValues).sort(
    sortBreakpoints(theme.breakpoints, true)
  );

  return (
    getClosesValue(
      breakpointValues,
      breakpoint,
      Object.keys(theme.breakpoints)
        .filter(isCustomBreakpoint)
        .sort(sortBreakpoints(theme.breakpoints))
    ) ?? breakpointValues[sortedBreakpoints[sortedBreakpoints.length - 1]]
  );
}
