/* eslint-disable @typescript-eslint/camelcase */
import config from '@/config';
import { Client } from '@/lib/storyblok';

const client = new Client(config.storyblok.token);

export const fetchLinks = () => client.get('cdn/links');

export const fetchAllStories = (parentId: number, query) =>
  client.get(`cdn/spaces/${parentId}/stories/`, {
    story_only: true,
    with_parent: parentId,
    is_published: true,
    ...query,
  });

export const fetchCommonContent = () =>
  client.get('cdn/stories/support/common', {
    // eslint-disable-next-line @typescript-eslint/camelcase
    resolve_relations: 'story.story',
    resolve_links: 'url',
  });

export const fetchPageContent = (slug: string, folder = '') =>
  client.get(`cdn/stories${folder}/${slug}`, {
    // eslint-disable-next-line @typescript-eslint/camelcase
    resolve_relations: 'story.story,feature-hero.swatches',
    resolve_links: 'url',
  });

export const fetchPage = (slug: string, folder = '') =>
  Promise.all([
    fetchCommonContent(),
    fetchPageContent(slug, folder),
    fetchLinks(),
  ]);

export default client;
