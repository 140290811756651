/* eslint-disable import/no-cycle */
import { FC } from 'react';

import SbEditable, { SbEditableContent } from 'storyblok-react';

import { SeoPlugin } from '@/types.d';

import Seo from '../../components/Seo';
import DynamicHero from '../../heros';
import DynamicComponent from '../../index';

const BasicPage: FC<{
  content: SbEditableContent & {
    hero: Array<SbEditableContent>;
    body: Array<SbEditableContent>;
    seo: SeoPlugin;
  };
}> = ({ content }) => (
  <SbEditable content={content}>
    {content.seo ? <Seo content={content.seo} /> : null}
    <DynamicHero content={content.hero[0]} />
    {content.body.map(blok => (
      <DynamicComponent key={blok._uid} content={blok} />
    ))}
  </SbEditable>
);

export default BasicPage;
