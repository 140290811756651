import { FC } from 'react';

import Head from 'next/head';

import useSocialMeta from '@/hooks/socialMeta';
import { SeoPlugin } from '@/types.d';

const Seo: FC<{
  content: SeoPlugin;
}> = ({ content }) => {
  const meta = useSocialMeta({
    description: content.description,
    ogDescription: content.og_description,
    ogImage: content.og_image,
    ogTitle: content.og_title,
    title: content.title,
    twitterDescription: content.twitter_description,
    twitterImage: content.twitter_image,
    twitterImageAlt: content.twitter_title,
    twitterTitle: content.twitter_title,
  });

  return (
    <Head>
      <>{meta}</>
    </Head>
  );
};

export default Seo;
