import { FC } from 'react';

import PreviewAlert from '@/components/Cms/PreviewAlert';
import { Footer, Header, Main } from '@/components/Layout';
import TrackScroll from '@/lib/GTM/TrackScroll';
import { LinkProvider } from '@/lib/storyblok/linksContext';
import storyblokService from '@/services/storyblok';
import * as Storyblok from '@/types.d';

const Page: FC<{
  preview: boolean;
  links: Storyblok.Links;
  content: Storyblok.Common;
}> = ({ preview, content, links, children }) => {
  return (
    <>
      <LinkProvider value={links}>
        <Header mainCtas={content.mainCtas} navLinks={content.navLinks} />
        <Main>{children}</Main>
        <TrackScroll name='footer' trackEnter>
          <Footer footerLinks={content.footerLinks} />
        </TrackScroll>
        {storyblokService.bridge()}
        <PreviewAlert preview={preview} />
      </LinkProvider>
    </>
  );
};

export default Page;
