import { AlertIcon, Alert, Box, chakra } from '@chakra-ui/react';
import Link from 'next/link';
import { useRouter } from 'next/router';

const AlertContainer = chakra(Box, {
  baseStyle: {
    position: 'fixed',
    bottom: 0,
    right: '0',
    margin: 'md',
    padding: 'md',
    cursor: 'pointer',
    zIndex: 'overlay',
    maxWidth: '100%',
  },
});

export default function PreviewAlert({ preview }) {
  const route = useRouter();
  return (
    preview && (
      <Link href={`/api/preview/exit?redirect=${route.asPath}`}>
        <AlertContainer>
          <Alert status='warning'>
            <AlertIcon />
            You are in preview mode. Click here to exit.
          </Alert>
        </AlertContainer>
      </Link>
    )
  );
}
