import { FC } from 'react';

import {
  AccordionButton,
  AccordionItem,
  AccordionPanel,
} from '@chakra-ui/react';

import SLink from '@/components/storyblok/components/Link';
import { Hr, VStack } from '@/elements';
import { Cta, List } from '@/types.d';

import SubNavCta from '../Header/SubNavCta';
import SubNavLinkList from '../Header/SubNavLinkList';

const DrawerMenuItem: FC<{
  item: Cta | List;
}> = ({ item }) => {
  switch (item.component) {
    case 'cta':
      return (
        <SLink
          key={item._uid}
          fontFamily='heading'
          link={item.link}
          variant='muted'
        >
          {item.title}
        </SLink>
      );
    case 'link-list':
      return (
        <AccordionItem key={item._uid}>
          <AccordionButton>{item.title}</AccordionButton>

          <AccordionPanel pb={4}>
            <VStack divider={<Hr variant='short' />}>
              {item.links.map(link => {
                switch (link.component) {
                  case 'link-list':
                    return (
                      <SubNavLinkList
                        key={link._uid}
                        alignItems='center'
                        content={link}
                        my='md'
                        spacing='sm'
                      />
                    );
                  case 'cta':
                    return <SubNavCta key={link._uid} content={link} />;
                  default:
                    throw Error(`${link.component} unrecognised`);
                }
              })}
            </VStack>
          </AccordionPanel>
        </AccordionItem>
      );
    default:
      throw new Error(`${item.component} is an unrecognised menu item`);
  }
};

export default DrawerMenuItem;
