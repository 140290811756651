import { FC } from 'react';

import {
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  PopoverArrow,
  Box,
} from '@chakra-ui/react';
import styled from '@emotion/styled';

import SLink from '@/components/storyblok/components/Link';
import { VStack, A, Hr } from '@/elements';
import { Cta, List } from '@/types.d';

import SubNavCta from '../SubNavCta';
import SubNavLinkList from '../SubNavLinkList';

const FixWidthContainer = styled.div`
  & > * {
    min-width: 200px;
    max-width: 400px;
    width: unset !important;
  }
`;

const MenuItem: FC<{
  item: Cta | List;
}> = ({ item }) => {
  switch (item.component) {
    case 'cta':
      return (
        <SLink key={item._uid} link={item.link} variant='muted'>
          {item.text}
        </SLink>
      );
    case 'link-list':
      return (
        <Box key={item._uid}>
          <Popover gutter={15} placement='bottom-start'>
            <PopoverTrigger>
              <A variant='muted'>{item.title}</A>
            </PopoverTrigger>
            <FixWidthContainer>
              <PopoverContent>
                <PopoverArrow />
                <PopoverBody
                  boxShadow='0px 4px 41px rgba(159, 178, 200, 0.4)'
                  onClick={() => {
                    // eslint-disable-next-line no-unused-expressions
                    (document.activeElement as any)?.blur?.();
                  }}
                >
                  <VStack
                    alignItems='flex-start'
                    divider={
                      <Box border='none' py='md' width='100%'>
                        <Hr maxW='40px' />
                      </Box>
                    }
                    px='md'
                    py='md'
                  >
                    {item.links.map(link => {
                      switch (link.component) {
                        case 'link-list':
                          return (
                            <SubNavLinkList key={link._uid} content={link} />
                          );
                        case 'cta':
                          return <SubNavCta key={link._uid} content={link} />;
                        default:
                          throw Error(`${link.component} unrecognised`);
                      }
                    })}
                  </VStack>
                </PopoverBody>
              </PopoverContent>
            </FixWidthContainer>
          </Popover>
        </Box>
      );
    default:
      throw new Error(`${item.component} is an unrecognised menu item`);
  }
};

export default MenuItem;
