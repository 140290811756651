import { useCallback, useEffect, useState } from 'react';

export default function useSocialMeta(props: {
  title?: string;
  description?: string;
  keywords?: string;
  image?: string;
  ogTitle?: string;
  ogUrl?: string;
  ogImage?: string;
  ogDescription?: string;
  twitterSite?: string;
  twitterTitle?: string;
  twitterDescription?: string;
  twitterImage?: string;
  twitterImageAlt?: string;
}) {
  const getMeta = useCallback(() => {
    const {
      title,
      description,
      keywords,
      image,
      ogTitle,
      ogUrl,
      ogImage,
      ogDescription,
      twitterSite,
      twitterTitle,
      twitterDescription,
      twitterImage,
      twitterImageAlt,
    } = props;
    const metaElems = [];

    /* <!-- Social --> */
    if (title)
      metaElems.push(
        <meta key='description' content={description} name='description' />
      );
    if (description)
      metaElems.push(
        <meta key='keywords' content={keywords} name='keywords' />
      );
    /* <!-- Schema.org markup for Google+ --> */
    if (title)
      metaElems.push(<meta key='name' content={title} itemProp='name' />);
    if (description)
      metaElems.push(
        <meta
          key='description-prop'
          content={description}
          itemProp='description'
        />
      );
    if (image)
      metaElems.push(<meta key='image' content={image} itemProp='image' />);
    /* <!-- Twitter Card data --> */
    if (
      twitterSite ||
      twitterTitle ||
      twitterDescription ||
      twitterImage ||
      twitterImageAlt
    )
      metaElems.push(
        <meta key='twitter:card' content='summary' name='twitter:card' />
      );
    if (twitterSite)
      metaElems.push(
        <meta key="'twitter:site'" content={twitterSite} name='twitter:site' />
      );
    if (twitterTitle)
      metaElems.push(
        <meta
          key="'twitter:title'"
          content={twitterTitle}
          name='twitter:title'
        />
      );
    if (twitterDescription)
      metaElems.push(
        <meta
          key="'twitter:description'"
          content={twitterDescription}
          name='twitter:description'
        />
      );
    if (twitterImage)
      metaElems.push(
        <meta
          key="'twitter:image'"
          content={twitterImage}
          name='twitter:image'
        />
      );
    if (twitterImageAlt)
      metaElems.push(
        <meta
          key="'twitter:image:alt'"
          content={twitterImageAlt}
          name='twitter:image:alt'
        />
      );
    /* <!-- Open Graph data --> */
    if (ogTitle || ogUrl || ogImage || ogDescription)
      metaElems.push(
        <meta key='og:type' content='website' property='og:type' />
      );
    if (ogTitle)
      metaElems.push(
        <meta key='og:title' content={ogTitle} property='og:title' />
      );
    if (ogUrl)
      metaElems.push(<meta key='og:url' content={ogUrl} property='og:url' />);
    if (ogImage)
      metaElems.push(
        <meta key='og:image' content={ogImage} property='og:image' />
      );
    if (ogDescription)
      metaElems.push(
        <meta
          key='og:description'
          content={ogDescription}
          property='og:description'
        />
      );

    return metaElems;
  }, [
    props.title,
    props.description,
    props.keywords,
    props.image,
    props.ogTitle,
    props.ogUrl,
    props.ogImage,
    props.ogDescription,
    props.twitterSite,
    props.twitterTitle,
    props.twitterDescription,
    props.twitterImage,
    props.twitterImageAlt,
  ]);
  const [meta, setMeta] = useState({ metaElems: getMeta() });

  useEffect(() => setMeta({ metaElems: getMeta() }), [getMeta]);

  return meta.metaElems;
}
