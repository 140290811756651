import React from 'react';

import { HamburgerIcon } from '@chakra-ui/icons';
import {
  Accordion,
  ChakraComponent,
  Button,
  IconButton,
  useDisclosure,
  useTheme,
} from '@chakra-ui/react';
import NextLink from 'next/link';

import { CtaRow } from '@/components/storyblok/components/Cta';
import {
  Drawer,
  DrawerBody,
  DrawerFooter,
  HStack,
  Image,
  VStack,
  A,
} from '@/elements';
import useBreakpointValue from '@/hooks/breakpoint';
import useModals from '@/lib/providers/modalProvider';
import { fsEvent } from '@/services/fullstory';
import * as Storyblok from '@/types.d';

import DrawerMenuItem from '../Drawer/DrawerMenuItem';

import MenuItem from './MenuItem';
import { Background, HeaderContainer } from './styled';
import useAutoHideHeader from './useAutoHideHeader';

export default function Header({ mainCtas, navLinks }: Storyblok.Header) {
  const theme = useTheme();
  const navHeight = theme.header.height;
  const { isAtTop, nav } = useAutoHideHeader({
    navHeight,
  });
  const hidden = useBreakpointValue({ base: true, md: false });
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { onOpen: openModal } = useModals();

  const onCTAClick = () => {
    fsEvent({ name: 'NAV - GET KUINUA' });
    openModal();
  };

  return (
    <>
      <HeaderContainer
        ref={nav as any}
        backgroundColor='white'
        boxShadow={!isAtTop && 'md'}
        height={`${navHeight}px`}
        justifyContent='space-between'
        p='md'
      >
        <Background />

        <HStack>
          <NextLink href='/' passHref>
            <Image cursor='pointer' src='/logo/FullLogo.svg' width='140px' />
          </NextLink>
          {hidden || (
            <HStack px='lg' spacing='lg'>
              {navLinks.map(link => (
                <MenuItem key={link._uid} item={link} />
              ))}
            </HStack>
          )}
        </HStack>
        <Button id='get-kuinua' color='white' onClick={onCTAClick} size='lg'>
          <span>📱</span>Get Kuinua
        </Button>
      </HeaderContainer>

      {!hidden || (
        <Drawer isOpen={isOpen} onClose={onClose} placement='right'>
          <DrawerBody>
            <Accordion allowToggle>
              <VStack px='lg' spacing='lg'>
                {navLinks.map(link => (
                  <DrawerMenuItem key={link._uid} item={link} />
                ))}
              </VStack>
            </Accordion>
          </DrawerBody>
          <DrawerFooter>
            <CtaRow
              Component={A as ChakraComponent<typeof A>}
              ctas={mainCtas}
              variant='blank'
            />
          </DrawerFooter>
        </Drawer>
      )}
    </>
  );
}
