import { useCallback, useEffect, useRef, useState } from 'react';

const getValidIndex = (index, length) => Math.max(0, index % length);

/**
 * state of this hook is memoized, it means it is safe to pass whole state as a prop it won`t trigger unnecessary re-render
 * e.g.
 *
 * const carouselState = useCarouselPositionManager();
 * return <SomeComponent carousel={carouselState} />
 *
 */
export default function useCarouselPositionManager(
  length = 0,
  { autoPlayInterval = 4000, defaultIndex = 0 } = {}
) {
  const timer = useRef(null);
  const [index, setIndex] = useState(getValidIndex(defaultIndex, length));
  const goTo = useCallback(
    (pos: number) => setIndex(getValidIndex(pos, length)),
    [index, length]
  );
  const next = useCallback(() => goTo(index + 1), [index, goTo]);
  const previous = useCallback(() => goTo(index - 1), [index, goTo]);
  const [state, setState] = useState({
    goTo,
    next,
    previous,
    pos: index,
  });

  useEffect(() => {
    setState({
      goTo,
      next,
      previous,
      pos: index,
    });
  }, [index, goTo, next, previous]);

  useEffect(() => {
    clearTimeout(timer.current);
    if (autoPlayInterval && length > 1) {
      timer.current = setTimeout(() => next(), autoPlayInterval);
    }
    return () => clearTimeout(timer.current);
  }, [length, index, autoPlayInterval]);

  return state;
}
