import { SbEditableContent } from 'storyblok-react';

import config from '@/config';
import { warn } from '@/services/log';

import DefaultPlaceholder from './Placeholder';

export default function createDynamicComponents(
  components,
  Placeholder = DefaultPlaceholder
) {
  return ({ content }: { content: SbEditableContent }) => {
    if (typeof components[content.component] !== 'undefined') {
      const Component = components[content.component];
      return <Component content={content} />;
    }
    if (config.env === 'development') {
      return <Placeholder name={content.component} />;
    }

    warn(new Error('Storyblok component has not been implemented'));
    return null;
  };
}
