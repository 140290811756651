import { Box } from '@chakra-ui/react';

export default function SectionRow({ children }) {
  return (
    <Box
      px={{
        base: 'md',
        sm: 'md',
        md: 'lg',
        lg: 'xl',
      }}
      width='100%'
    >
      {children}
    </Box>
  );
}
