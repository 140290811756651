import { FC, useEffect, useState } from 'react';

import { Box, Flex } from '@chakra-ui/react';
import SbEditable from 'storyblok-react';

import { Section } from '@/components/Layout';
import {
  VStack,
  H1,
  Text,
  FadeOnScroll,
  HStack,
  FadeInImage,
  AppStoreBadge,
  PlayStoreBadge,
} from '@/elements';
import useModals from '@/lib/providers/modalProvider';
import { fsEvent } from '@/services/fullstory';
import { FeatureHero as TFeatureHero } from '@/types.d';

import { IsLarge, IsSmall } from './styled';

const FeatureHero: FC<{
  content: TFeatureHero;
}> = ({ content }) => {
  const [isClient, setIsClient] = useState(false);
  const { onOpen } = useModals();

  const onStoreBadgeClick = (storeName: string) => {
    fsEvent({ name: 'Download', properties: { storeName } });
    onOpen();
  };

  useEffect(() => {
    setIsClient(true);
  }, []);

  return (
    <>
      <IsSmall>
        <SbEditable content={content}>
          <Section>
            <VStack
              maxW='mobileContentWidth'
              mx='auto'
              position='relative'
              py={{ base: 'lg', tablet: 'xl' }}
              spacing='0px'
            >
              <Flex
                alignItems='center'
                flex={1}
                pointerEvents='none'
                position='relative'
              >
                {isClient && (
                  <>
                    <FadeInImage
                      height='300px'
                      alt={content.hero_image.alt}
                      src={content.hero_image.filename}
                    />
                  </>
                )}
              </Flex>
              <Box w='100%'>
                <VStack align='flex-start' m='md' spacing='lg'>
                  <FadeOnScroll>
                    <H1 fontWeight='600' mt='lg' whiteSpace='pre-line'>
                      {content.title}
                    </H1>
                    {content.description && (
                      <Text fontFamily='body' variant='fancy'>
                        {content.description}
                      </Text>
                    )}
                    <VStack margin='lg' justifyContent='space-around'>
                      <Box
                        as='button'
                        onClick={() => onStoreBadgeClick('AppStore')}
                      >
                        <AppStoreBadge />
                      </Box>
                      <Box
                        as='button'
                        onClick={() => onStoreBadgeClick('PlayStore')}
                      >
                        <PlayStoreBadge />
                      </Box>
                    </VStack>
                  </FadeOnScroll>
                </VStack>
              </Box>
            </VStack>
          </Section>
        </SbEditable>
      </IsSmall>
      <IsLarge>
        <SbEditable content={content}>
          <Box position='relative'>
            <Section>
              <HStack>
                <Box flex={1.2}>
                  <VStack align='flex-start' spacing='lg'>
                    <FadeOnScroll>
                      <H1 fontWeight='600' mb='lg'>
                        {content.title}
                      </H1>
                      {content.description && (
                        <Text
                          fontFamily='body'
                          fontSize='lg'
                          lineHeight='lg'
                          mb='lg'
                        >
                          {content.description}
                        </Text>
                      )}
                      <HStack>
                        <Box
                          id='get-ios'
                          as='button'
                          onClick={() => onStoreBadgeClick('AppStore')}
                        >
                          <AppStoreBadge />
                        </Box>
                        <Box
                          id='get-android'
                          as='button'
                          onClick={() => onStoreBadgeClick('PlayStore')}
                        >
                          <PlayStoreBadge />
                        </Box>
                      </HStack>
                    </FadeOnScroll>
                  </VStack>
                </Box>
                <Box flex={1} pl={20}>
                  {isClient && (
                    <>
                      <Flex>
                        <FadeInImage
                          alt={content.hero_image.alt}
                          src={content.hero_image.filename}
                          width='100%'
                        />
                      </Flex>
                    </>
                  )}
                </Box>
              </HStack>
            </Section>
          </Box>
        </SbEditable>
      </IsLarge>
    </>
  );
};

export default FeatureHero;
