import { FC, useCallback } from 'react';

import { Waypoint } from 'react-waypoint';

import { track } from './service';

const TrackScroll: FC<{
  name: string;
  trackEnter?: boolean;
  enter?: {
    [prop: string]: any;
  };
  trackExit?: boolean;
  exit?: {
    [prop: string]: any;
  };
}> = ({ name, trackEnter, enter, trackExit, exit, children, ...rest }) => {
  const trackOnEnter = useCallback(() => {
    if (trackEnter)
      track('scroll.enter', {
        name,
        ...enter,
      });
  }, [trackEnter, enter]);
  const trackOnExit = useCallback(
    ({ currentPosition }) => {
      if (trackExit)
        track('scroll.exit', {
          name,
          position: currentPosition,
          ...exit,
        });
    },
    [trackExit, exit]
  );
  return (
    <Waypoint onEnter={trackOnEnter} onLeave={trackOnExit} {...rest}>
      <div>{children}</div>
    </Waypoint>
  );
};

export default TrackScroll;
