/* eslint-disable import/no-cycle */
import { components as heros } from './heros';
import { components as pages } from './pages';
import { components as sections } from './sections';
import createDynamicComponents from './support/createDynamicComponents';

const components = {
  ...sections,
  ...heros,
  ...pages,
};

const DynamicComponent = createDynamicComponents(components);

export default DynamicComponent;
