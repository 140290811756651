import { FC } from 'react';

import { Box } from '@chakra-ui/react';

const padding = {
  base: 'sm',
  sm: 'md',
  md: 'lg',
  lg: 'xl',
};

const SectionColumn: FC<{ ptOnly?: boolean }> = ({ children, ptOnly }) => {
  if (ptOnly) {
    return <Box pt={padding}>{children}</Box>;
  }
  return <Box py={padding}>{children}</Box>;
};
export default SectionColumn;
