import { FC } from 'react';

import { Text } from '@chakra-ui/react';

import SLink from '@/components/storyblok/components/Link';
import { VStack } from '@/elements';
import { List } from '@/types.d';

const SubNavLinkList: FC<{
  content: List;
  my?: string;
  spacing?: string;
  alignItems?: string;
}> = ({ content, my = 'sm', spacing = 'md', alignItems = 'flex-start' }) => (
  <VStack key={content._uid} alignItems={alignItems} my={my} spacing={spacing}>
    <Text
      color='brand.mediumGrey'
      fontFamily='heading'
      fontWeight='400'
      pb='sm'
    >
      {content.title}
    </Text>
    {content.links.map(
      subLink =>
        subLink.component === 'cta' && (
          <SLink key={subLink._uid} link={subLink.link} variant='mutedSimple'>
            {subLink.text}
          </SLink>
        )
    )}
  </VStack>
);

export default SubNavLinkList;
