import { chakra, Box } from '@chakra-ui/react';

const SectionContentContainer = chakra(Box, {
  baseStyle: {
    width: '100%',
    maxWidth: 'lg',
    margin: '0 auto',
  },
});

export default function SectionContent({ children }) {
  return <SectionContentContainer>{children}</SectionContentContainer>;
}
