import { FC } from 'react';

import { Box, Stack } from '@chakra-ui/react';
import styled from '@emotion/styled';
import SbEditable from 'storyblok-react';

import { Section } from '@/components/Layout';
import { FadeOnScroll, H1 } from '@/elements';
import { FeatureListSection as FeatureListSectionContent } from '@/types.d';

import FeatureList from './FeatureList';

const Shadow = styled.div`
  position: relative;
  height: 100%;
  ::before {
    content: ' ';
    position: absolute;
    top: 8px;
    width: 100%;
    left: 8px;
    height: 100%;
    background: linear-gradient(180deg, #e3e0ff 0%, #eaefff 97.28%);
    filter: blur(20.7742px);
    border-radius: 20px;
  }
`;

export const FullHeightReveal = styled.div`
  height: 100%;
  & > .react-reveal {
    height: 100%;
  }
`;

const FeatureListSection: FC<{
  content: FeatureListSectionContent;
}> = ({ content }) => (
  <SbEditable content={content}>
    <Box m='md' py='lg'>
      <Section>
        <FadeOnScroll>
          <H1 fontWeight='600' mt='lg' pb='lg'>
            {content.title}
          </H1>
        </FadeOnScroll>
        <Stack
          align='stretch'
          direction={
            {
              base: 'column',
              tablet: 'row',
            } as any
          }
          justifyItems='stretch'
          spacing='xl'
        >
          {content.list.map(list => (
            <Box key={list._uid} borderRadius='20px' flex={1}>
              <FullHeightReveal>
                <FadeOnScroll style={{ height: '100%' }}>
                  <Shadow>
                    <Box
                      background='white'
                      borderRadius='20px'
                      height='100%'
                      p='lg'
                      position='relative'
                      zIndex={1}
                    >
                      <FeatureList content={list} />
                    </Box>
                  </Shadow>
                </FadeOnScroll>
              </FullHeightReveal>
            </Box>
          ))}
        </Stack>
      </Section>
    </Box>
  </SbEditable>
);

export default FeatureListSection;
