import { FC } from 'react';

import { Box, Stack, VStack } from '@chakra-ui/react';
import SbEditable from 'storyblok-react';

import { Section } from '@/components/Layout';
import RoundImage from '@/components/RoundImage';
import { FadeOnScroll, H2, H4 } from '@/elements';
import { ColumnsSection as TColumnsSection } from '@/types.d';

import { RichText } from '../../components';

const ColumnsSection: FC<{
  content: TColumnsSection;
}> = ({ content }) => {
  return (
    <SbEditable content={content}>
      <Section>
        <VStack
          alignItems='flex-start'
          maxW={{ base: 'mobileContentWidth', md: 'none' }}
          mx='auto'
        >
          {content.subtitle && (
            <FadeOnScroll>
              <H4>{content.subtitle}</H4>
            </FadeOnScroll>
          )}
          <FadeOnScroll>
            <H2 mb='lg'>{content.title}</H2>
          </FadeOnScroll>
          <Stack
            direction={{ base: 'column', md: 'row' }}
            spacing='lg'
            width='100%'
          >
            {content.columns.map(column => (
              <Box key={column._uid} flex='1' maxW='mobileContentWidth'>
                <SbEditable content={column}>
                  <VStack alignItems='flex-start' spacing='md'>
                    <FadeOnScroll>
                      <RoundImage
                        alt={column.icon.alt}
                        mx='0'
                        size='80px'
                        src={column.icon.filename}
                        title={column.icon.title}
                      />
                      <H4 py='sm'>{column.title}</H4>
                      <Box>
                        <RichText>{column.body}</RichText>
                      </Box>
                    </FadeOnScroll>
                  </VStack>
                </SbEditable>
              </Box>
            ))}
          </Stack>
        </VStack>
      </Section>
    </SbEditable>
  );
};

export default ColumnsSection;
