import { AspectRatio, Image } from '@chakra-ui/react';

export default function RoundImage({
  alt,
  src,
  title,
  mx = 'auto',
  size = '100%',
  ...rest
}) {
  return (
    <AspectRatio
      background='white'
      border='1px solid transparent'
      borderColor='brand.lightGrey'
      borderRadius='50%'
      boxShadow='lg'
      mx={mx}
      ratio={1}
      width={size}
      {...rest}
    >
      {src ? (
        <Image
          alt={alt}
          borderRadius='full'
          height='100%'
          src={src}
          title={title}
          width='100%'
        />
      ) : null}
    </AspectRatio>
  );
}
