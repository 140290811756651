import { FC } from 'react';

import { Box } from '@chakra-ui/react';
import SbEditable from 'storyblok-react';

import { Section } from '@/components/Layout';
import { FadeOnScroll } from '@/elements';
import { Statement } from '@/types.d';

import { RichText } from '../../components';

import { BackgroundImage } from './styled';

const StatementSection: FC<{
  content: Statement;
}> = ({ content }) => {
  return (
    <SbEditable content={content}>
      <Box
        alignItems='center'
        d='flex'
        justifyContent='center'
        minHeight={300}
        position='relative'
        py='xl'
      >
        <BackgroundImage alt='Background' src='/assets/statement-bg.svg' />
        <Box position='relative' zIndex={1}>
          <Section>
            <Box
              display='flex'
              fontSize={{ base: 'lg', xl: 'xl' }}
              lineHeight={{ base: 'lg', xl: 'xl' }}
              maxW={{ base: '600px', xl: 'none' }}
              textAlign='center'
            >
              <FadeOnScroll>
                <Box>
                  <RichText>{content.statement}</RichText>
                </Box>
              </FadeOnScroll>
            </Box>
          </Section>
        </Box>
      </Box>
    </SbEditable>
  );
};

export default StatementSection;
